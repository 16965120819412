import posthog from 'posthog-js';
import { useEffect, useState } from 'react';

export enum Flags {
  IS_EDIT_BID_VISIBLE = 'is-edit-bid-visible',
  IS_CANCEL_BUTTON_VISIBLE = 'is-cancel-button-visible',
  IS_MAGIC_LINKS_VISIBLE = 'is-magic-links-visible',
  IS_BID_COUNT_VISIBLE = 'is-bid-count-visible',
}

export const useFlags = (featureFlag: string): boolean => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const flag = posthog.isFeatureEnabled(featureFlag);
    if (flag) {
      setVisible(true);
    }
  }, [featureFlag]);

  return visible;
};
